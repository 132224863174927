import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons';
import { beemindLogoBackground } from '../svg/beemind-logo';
import { useHistory } from 'react-router-dom';

const Project1: React.SFC<{}> = () => {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='copy'>
          <p className='subtitle'>2020</p>
          <p className='title'>Sanboard</p>
        </div>

        <div
          className='bottom-scroll-element'
          onClick={() =>
            window.scrollBy({
              top: 250,
              left: 0,
              behavior: 'smooth',
            })
          }
        >
          <p>Scrollen</p>
          <FontAwesomeIcon
            icon={faAngleDoubleDown}
            style={{ margin: '5px 0px' }}
          />
        </div>
        <div
          className='bottom-navigation-element'
          onClick={() => {
            window.scrollTo(0, 0);
            history.push('/projects-webdesign');
          }}
        >
          <p>Nächstes</p>
          <p>Projekt</p>
          <FontAwesomeIcon icon={faAngleRight} style={{ margin: '0 6px' }} />
        </div>
        <div className='project-background'>{beemindLogoBackground}</div>
      </div>

      <div className='content-container'>
        <p className='year'>2020</p>
        <p className='title'>Sanboard</p>
        <p className='values'>EINFACH. SICHER. SCHNELL.</p>
        <p className='subtitle'>Die Software für deinen Samariterverein.</p>
        <p className='body'>
          Sanboard vereinfacht und automatisiert sämtliche administrative
          Aufgaben eines Samaritervereins. Einsätze können mit der geführten
          Risikobeurteilung optimal geplant und durchgeführt werden. Diverse
          Funktionen erlauben die einfache Mitglieder- und Inventarverwaltung.
          Die Sicherheit der Daten hat bei Sanboard erste Priorität. Alle
          Patientendaten werden verschlüsselt und in einem Schweizer Datencenter
          gespeichert.
        </p>
        <p
          className='link'
          onClick={() => window.open('https://www.sanboard.ch')}
        >
          www.sanboard.ch
        </p>
        <button
          className='basic-button'
          onClick={() => window.open('https://www.sanboard.ch')}
        >
          Mehr erfahren
        </button>
      </div>
    </React.Fragment>
  );
};

export default Project1;
