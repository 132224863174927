import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { beemindLogoBackground } from '../svg/beemind-logo';
import { useHistory } from 'react-router-dom';

const Project2: React.SFC<{}> = () => {
  let history = useHistory();
  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='copy'>
          <p className='subtitle'>2020</p>
          <p className='title'>Webdesign</p>
        </div>

        <div
          className='bottom-scroll-element'
          onClick={() =>
            window.scrollBy({
              top: 250,
              left: 0,
              behavior: 'smooth',
            })
          }
        >
          <p>Scrollen</p>
          <FontAwesomeIcon
            icon={faAngleDoubleDown}
            style={{ margin: '5px 0px' }}
          />
        </div>
        <div
          className='bottom-navigation-element'
          onClick={() => {
            window.scrollTo(0, 0);
            history.push('/projects');
          }}
        >
          <p>Vorheriges</p>
          <p>Projekt</p>
          <FontAwesomeIcon icon={faAngleLeft} style={{ margin: '0 6px' }} />
        </div>
        <div className='project-background'>{beemindLogoBackground}</div>
      </div>

      <div className='content-container'>
        <p className='year'>2020</p>
        <p className='title'>Webdesign</p>
        <p className='values'>PERSÖNLICH.</p>
        <p className='subtitle'>BeeMind unterstützt Ihren Webauftritt.</p>
        <p className='body'>
          Haben Sie sich gerade selbstständig gemacht und brauchen eine
          Firmenhomepage? Oder möchten Sie ihrer Homepage einen neuen Look
          verpassen? <br />
          <br />
          Je nach Anforderungen an die Website arbeiten wir mit einem
          Baukastensystem von Wordpress oder programmieren die komplette Website
          neu. <br />
          <br />
          Gerne nehmen wir uns Zeit und schauen uns ihre Wünsche in einem
          Gespräch genauer an.
        </p>

        <p
          className='link'
          onClick={() => window.open('mailto:info@beemind.ch')}
        >
          info@beemind.ch
        </p>
        <button
          className='basic-button'
          onClick={() => history.push('/contact')}
        >
          Kontakt aufnehmen
        </button>
      </div>
    </React.Fragment>
  );
};

export default Project2;
