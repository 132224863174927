import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { beemindLogoBackground } from '../svg/beemind-logo';
import { useHistory } from 'react-router-dom';

const LandingPage: React.FC<{}> = () => {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className='first-fold'>
        <div className='copy'>
          <p className='big-phrase'>
            Wir sind
            <br /> BeeMind.
          </p>

          <p className='catch-phrase'>{`// Software, die funktioniert.`}</p>
        </div>
        <div className='first-fold-background'>{beemindLogoBackground}</div>
        <div className='links-container'>
          <div className='left-links'>
            <p onClick={() => history.push('/team')}>Team</p>{' '}
            <p onClick={() => history.push('/contact')}>Kontakt</p>
          </div>
          <div
            className='right-links'
            onClick={() => history.push('/projects')}
          >
            <p>Projekte</p>
            <FontAwesomeIcon icon={faAngleRight} style={{ margin: '0 6px' }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
