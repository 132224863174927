import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { beemindLogo } from '../svg/beemind-logo';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar: React.SFC<{}> = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  let history = useHistory();

  const toggledNavbarClassname = showNavbar
    ? 'toggled-navbar'
    : 'toggled-navbar hide';

  return (
    <React.Fragment>
      <div className='navbar'>
        <div
          className='menu-toggle-container'
          onClick={() => setShowNavbar(!showNavbar)}
        >
          <div className={showNavbar ? 'vertical-hamburger' : 'hamburger'}>
            <div className='one'></div>
            <div className='two'></div>
            <div className='three'></div>
          </div>
          <p>{showNavbar ? 'schliessen' : 'menu'}</p>
        </div>

        <div
          className='logo-container'
          onClick={() => {
            history.push('/');
          }}
        >
          {beemindLogo}
        </div>
      </div>

      <div className={toggledNavbarClassname}>
        <div className='links'>
          <p
            onClick={() => {
              history.push('/projects');
              setShowNavbar(false);
            }}
          >
            Projekte
          </p>
          <p
            onClick={() => {
              history.push('/projects');
              setShowNavbar(false);
            }}
          >
            Sanboard
          </p>
          <p
            onClick={() => {
              history.push('/projects-webdesign');
              setShowNavbar(false);
            }}
          >
            Webdesign
          </p>
          <p
            onClick={() => {
              history.push('/team');
              setShowNavbar(false);
            }}
          >
            Team
          </p>
          <p
            onClick={() => {
              history.push('/contact');
              setShowNavbar(false);
            }}
          >
            Kontakt
          </p>
        </div>
        <div className='close' onClick={() => setShowNavbar(false)}>
          <FontAwesomeIcon icon={faTimes} style={{ margin: '5px 0px' }} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
