import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import Project1 from './components/Project1';
import Project2 from './components/Project2';
import Team from './components/Team';
import Contact from './components/Contact';

const AppRouter: React.SFC<{}> = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/projects' component={Project1} />
        <Route exact path='/projects-webdesign' component={Project2} />
        <Route exact path='/team' component={Team} />
        <Route exact path='/contact' component={Contact} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
