import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { beemindLogoBackground } from '../svg/beemind-logo';
import { useHistory } from 'react-router-dom';

const Team: React.SFC<{}> = () => {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='copy'>
          <p className='subtitle'>Dein</p>
          <p className='title'>Team</p>
        </div>

        <div
          className='bottom-scroll-element'
          onClick={() =>
            window.scrollBy({
              top: 250,
              left: 0,
              behavior: 'smooth',
            })
          }
        >
          <p>Scrollen</p>
          <FontAwesomeIcon
            icon={faAngleDoubleDown}
            style={{ margin: '5px 0px' }}
          />
        </div>

        <div className='project-background'>{beemindLogoBackground}</div>
      </div>

      <div className='content-container'>
        <p className='year'>Dein</p>
        <p className='title'>Team</p>
        <p className='values'>DIREKT. FLEISSIG.</p>
        <img src='/marc-team-small.jpg' alt='' className='team' />
        <p className='team-name'>Marc Wittwer</p>
        <p className='team-function'>Gründer, Software Entwicklung</p>
        <p className='body'>
          Eine der fleissigen Bienen bei BeeMind, die stets die Übersicht im
          Bienenstock behält. Durch das Verwenden von den neusten Technologien
          bleibt das Programmieren stets interessant und fordernd. Meine
          Faszination von einer Idee zu einem fertigen Produkt zu kommen, kann
          ich bei den Projekten von BeeMind voll und ganz ausleben.
        </p>
        <p
          className='link'
          onClick={() => window.open('mailto:marc.wittwer@beemind.ch')}
        >
          marc.wittwer@beemind.ch
        </p>
        <button
          className='basic-button'
          onClick={() => history.push('/contact')}
        >
          Kontakt aufnehmen
        </button>
      </div>
    </React.Fragment>
  );
};

export default Team;
