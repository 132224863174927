import React from 'react';
import { beemindLogoBackground } from '../svg/beemind-logo';

const Contact: React.SFC<{}> = () => {
  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='project-background'>{beemindLogoBackground}</div>
      </div>

      <div className='fixed-content-container'>
        <p className='title'>Kontakt</p>
        <p className='text'>
          BeeMind hat ihren Sitz in Zürich. <br />
          Wir freuen uns über jede Nachricht.
        </p>{' '}
        <p
          className='link'
          onClick={() => window.open('mailto:info@beemind.ch')}
        >
          info@beemind.ch
        </p>
        <p className='details-body'>BeeMind GmbH</p>
        <p className='details-body'>Rautistrasse 359</p>
        <p className='details-body'>8048 Zürich</p>
        <p className='details-body'>CH - Schweiz</p>
        <p className='copyright'>
          Copyright © {new Date().getFullYear()} BeeMind GmbH
        </p>
      </div>
    </React.Fragment>
  );
};

export default Contact;
